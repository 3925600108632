





































































































































































































































































import { Nullable } from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const CitiesStore = namespace("CitiesStore");
const LocationsStore = namespace("LocationsStore");
import { CityModel } from "@/core/models/CityModel";
import { AreaModel } from "@/core/models/AreaModel";
import FormGroup from "@/components/forms/FormGroup.vue";
import Field from "@/components/forms/Field.vue";
import BaseIcon from "@/components/base/BaseIcon.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import BaseSelect from "@/components/forms/BaseSelect/BaseSelect.vue";
import BaseTextarea from "@/components/forms/BaseTextarea.vue";
import BaseCheckbox from "@/components/forms/BaseCheckbox.vue";
import BaseDropdown from "@/components/forms/BaseDropdown.vue";
import axios from "axios";
import { GpsCoordsModel, MetroStation, LocationModel } from "@/core/models/LocationModel";
import { toLocalDuration } from "@/utils/durationFormatter";
import breakpoints from "@/plugins/breakpoints";
import vClickOutside from "v-click-outside";

interface Metro {
  city: number;
  color: string;
  id: number;
  name: string;
}

const validations = {
  name: { required, maxLength: maxLength(250) },
  cityId: { required },
  address: { required },
  phone: { required, minLength: minLength(16) },
  whatsapp: { maxLength: maxLength(255) },
  telegram: { maxLength: maxLength(255) },
  wayDirections: { maxLength: maxLength(3000) },
  parkingInfo: { maxLength: maxLength(3000) },
};

@Component({
  name: "AddAddressForm",
  components: {
    BaseIcon,
    BaseSelect,
    FormGroup,
    Field,
    BaseButton,
    BaseTextarea,
    BaseCheckbox,
    BaseDropdown,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  validations,
})
export default class AddAddressForm extends Vue {
  @Prop({ required: true })
  value!: LocationModel;

  @Watch("value")
  async onValueChanged(val: LocationModel): Promise<void> {
    this.updateModel(val);
    if (!this.address) {
      await this.setAddressField();
    }
  }

  @Watch("cityId")
  async onCityChanged(val: Nullable<number>, oldVal: Nullable<number>): Promise<void> {
    if (oldVal !== null) {
      this.metroStations = [];
      this.areas = [];
    }

    if (this.address?.value) {
      await this.setRecommendedLocations();
    }
  }

  id?: number | null = null;
  name = "";
  cityId: null | number = null;
  address: any = {};
  addressLegacy: any = "";
  phone = "";
  whatsapp = "";
  telegram = "";
  parkingInfo = "";
  wayDirections = "";
  metroStations: MetroStation[] = [];
  areaId: null | number = null;
  areas: AreaModel[] = [];
  active = true;
  gpsCoords = new GpsCoordsModel();

  allMetroList: Metro[] = [];
  allAreaList: AreaModel[] = [];
  recommendedLocations: any[] = [];
  addressData: any = {};
  cityData: CityModel | undefined;
  isFocusAddressField = false;
  isValidAddressField = true;

  toLocalDuration = toLocalDuration;

  breakpoints = breakpoints;

  get isMobile(): boolean {
    return breakpoints.width <= 768;
  }

  get metroList(): Metro[] {
    return this.allMetroList.filter((metro) => metro.city === this.cityId) ?? [];
  }

  get areaList(): AreaModel[] {
    return this.allAreaList.filter((area) => area.city === this.cityId) ?? [];
  }

  get nameErrorText(): string | null {
    const field = this.getValidationField("name");
    const isDirty = field.$dirty;

    if (isDirty && !field.required) {
      return "Это обязательное поле";
    } else if (isDirty && !field.maxLength) {
      return "Максимальная длина - 250 символов";
    }

    return null;
  }

  get phoneErrorText(): string | null {
    const field = this.getValidationField("phone");
    const isDirty = field.$dirty;

    if (isDirty && !field.required) {
      return "Это обязательное поле";
    } else if (isDirty && !field.minLength) {
      return "Телефон должен быть заполнен полностью";
    }

    return null;
  }

  get whatsappErrorText(): string | null {
    const field = this.getValidationField("whatsapp");
    const isDirty = field.$dirty;

    if (isDirty && !field.required) {
      return "Это обязательное поле";
    } else if (isDirty && !field.minLength) {
      return "Whatsapp должен быть заполнен полностью";
    }

    return null;
  }

  get telegramErrorText(): string | null {
    const field = this.getValidationField("telegram");
    const isDirty = field.$dirty;

    if (isDirty && !field.required) {
      return "Это обязательное поле";
    } else if (isDirty && !field.minLength) {
      return "Telegram должен быть заполнен полностью";
    }

    return null;
  }

  get addressFieldErrorText(): string | null {
    if (!this.isValidAddressField) {
      return "Введите номер дома";
    }
    return this.$v.address.$dirty && !this.$v.address.required
      ? "Поле не заполнено"
      : null;
  }

  get isOpenRecommendedLocationsDropdown(): boolean {
    return !!this.recommendedLocations.length && this.isFocusAddressField;
  }

  mounted() {
    console.log("blkablab >>>", this.$v);
  }

  @CitiesStore.Getter
  public cityList!: CityModel[];

  @CitiesStore.Action
  private getCities!: () => Promise<CityModel[]>;

  @LocationsStore.Action
  private getRecommendedLocations!: (payload: any) => Promise<any>;

  public isJsonString(string: any): boolean {
    try {
      JSON.parse(string);
    } catch (error) {
      return false;
    }
    return true;
  }

  public changeMain(option: MetroStation): void {
    if (option.main) return;
    this.metroStations.forEach((item) => {
      item.main = item.id === option.id ? true : false;
    });
    this.update();
  }

  public updateMetroStations(): void {
    const main = !this.metroStations.some((item) => item.main);
    this.metroStations.forEach((item) => {
      if (item.time === undefined) {
        this.$set(item, "main", main);
        this.$set(item, "time", null);
        this.$nextTick(() => {
          this.$emit("station-added", item);
        });
      }
    });
    if (this.metroStations.length && !this.metroStations.some((item) => item.main)) {
      this.metroStations[0].main = true;
    }
    this.update();
  }

  public async updateModel(data: LocationModel): Promise<void> {
    this.id = data?.id ?? null;
    this.name = data?.name ?? "";
    this.cityId = data?.cityId ?? null;
    this.areaId = data?.areaId ?? null;
    this.address = this.isJsonString(data?.address)
      ? JSON.parse(data?.address)
      : data?.address ?? "";
    this.addressLegacy = data?.addressLegacy ?? "";
    this.phone = data?.phone ?? "";
    this.whatsapp = data?.whatsapp ?? "";
    this.telegram = data?.telegram ?? "";
    this.wayDirections = data?.wayDirections ?? "";
    this.parkingInfo = data?.parkingInfo ?? "";
    this.gpsCoords = data?.gpsCoords ?? new GpsCoordsModel();
    this.metroStations = data?.metroStations ?? [];
    this.active = data?.active ?? false;
    this.addressData = data?.addressData ?? {};
  }

  public update(): void {
    this.$emit("input", {
      id: this.id,
      name: this.name,
      cityId: this.cityId,
      areaId: this.areaId,
      address: this.address,
      addressLegacy: this.addressLegacy,
      phone: this.phone,
      whatsapp: this.whatsapp,
      telegram: this.telegram,
      wayDirections: this.wayDirections,
      parkingInfo: this.parkingInfo,
      gpsCoords: this.gpsCoords,
      metroStations: this.metroStations,
      active: this.active,
      addressData: this.addressData,
    });
  }

  public async onUpdateAddress(): Promise<void> {
    await this.setRecommendedLocations();
    this.update();
  }

  public async setRecommendedLocations(): Promise<void> {
    this.cityData = this.cityList.find((city) => city.id === this.cityId);

    this.recommendedLocations = await this.getRecommendedLocations({
      query: this.address.value,
      cityName: this.cityData!.name,
    });

    this.recommendedLocations.map((item) => {
      item.value = item.value.split(`г ${this.cityData?.name}, `).join("");
      item.value = item.value
        .split(`${item.data.region} ${item.data.region_type}, `)
        .join("");
    });
  }

  public async fetchCityList(): Promise<any> {
    return await axios.get("/api/b2b/v1/cities");
  }

  public async fetchMetroList(): Promise<any> {
    return await axios.get("/api/b2b/v1/metro-stations");
  }

  public async fetchAreaList(): Promise<any> {
    return await axios.get("/api/b2b/v1/areas");
  }

  public getValidationField(key: string): any {
    return this.$v[key];
  }

  public checkValidity(): boolean {
    this.$v.$touch();

    this.setIsValidAddressField();

    return !this.$v.$anyError && this.isValidAddressField;
  }

  public async setAddressField(): Promise<void> {
    this.address = {
      value: this.addressLegacy,
    };

    await this.setRecommendedLocations();

    const addressFieldElement: any = this.$refs.addressField;
    addressFieldElement?.$el.querySelector(".Field__input").focus();
  }

  public setIsValidAddressField(): void {
    if (!this.addressData.data?.house) {
      this.isValidAddressField = false;
    } else {
      this.isValidAddressField = true;
    }
  }

  public deleteStation(id: number): void {
    const index = this.metroStations.findIndex((item) => item.id === id);
    this.metroStations.splice(index, 1);
  }

  public onFocusAddressField(): void {
    this.isFocusAddressField = true;
  }

  public onBlurAddressField(): void {
    this.$v.address.$touch();
  }

  public onClickRecommendedLocation(currentAddressData: any): void {
    this.address.value = currentAddressData.value;
    this.addressData = currentAddressData;
    this.gpsCoords = {
      lat: currentAddressData.data.geo_lat,
      lng: currentAddressData.data.geo_lon,
    };
    this.update();
    this.setIsValidAddressField();
    this.isFocusAddressField = false;
  }

  public onClickOutsideAddressField(event: Event): void {
    if (!(event.target as HTMLElement).closest(".AddAddressForm__address-dropdown")) {
      this.isFocusAddressField = false;
    }
  }

  public onChangeCity(): void {
    this.address = { value: "" };
    this.recommendedLocations = [];
    this.update();
  }

  public onChangeArea(): void {
    this.update();
  }

  public created(): void {
    this.getCities();

    this.fetchMetroList().then(({ data }) => {
      this.allMetroList = data.data as Metro[];
    });

    this.fetchAreaList().then(({ data }) => {
      this.allAreaList = data.data as AreaModel[];
    });
  }
}
